<template>
    <div class="">
        <div class="row">
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="inputName">
                        Name
                        <span class="text-danger small">*</span>
                    </label>
                    <input type="text" v-validate="{ required: true}" v-model="data.name"
                      id="inputName" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
                      placeholder="Enter name" />
                    <span class="text-danger small"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="inputTitle">
                         Title
                        <span class="text-danger small">*</span>
                    </label>
                    <input type="text" v-validate="{ required: true}" v-model="data.title"
                      id="inputTitle" name="title" class="form-control" :class="{ 'is-invalid': errors.has('title') }"
                      placeholder="Enter question" />
                    <span class="text-danger small"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
                </div>
            </div>
            <!-- <div class="col-12">
                <div class="form-group mb-3">
                    <label for="inputOrder">
                        Sort Order
                    </label>
                    <input type="number" id="inputOrder" v-model="data.sort_order" v-validate="{ required: true }" class="form-control"
                     name="sort_order" :class="{ 'is-invalid': errors.has('sort_order')}" placeholder="sort order" />
                    <span class="text-danger small" v-show="errors.has('sort_order')">{{ errors.first('sort_order') }}</span>
                </div>
            </div> -->
            <div class="col-12">
                <div class="form-group mb-3">
                    <label class="">Type</label>
                    <select class="custom-select" v-validate="{required: true}" v-model="data.type"
                     name="type" placeholder="--select type--" :class="{ 'is-invalid': errors.has('type')}">
                        <option disabled value="">--select type--</option>
                        <option value="pro_input">Simple Input</option>
                        <option value="pro_textarea">Textarea</option>
                        <option value="pro_checkbox">Checkbox</option>
                        <option value="pro_select">Select Input</option>
                        <option value="pro_radio">Radio Input</option>
                        <option value="photo_gallery">Photo Gallery</option>
                        <option value="file_uploader">File Uploader</option>
                        <option value="color_gallery">Color Gallery</option>
                    </select>
                    <span class="text-danger small"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="form-group mb-3">
                    <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                        Visibility
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="form-group mb-3">
                    <b-form-checkbox v-model="data.multiple" name="multiple" switch>
                        Multiple
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="form-group mb-3">
                    <b-form-checkbox v-model="data.required" name="multiple" switch>
                        Required
                    </b-form-checkbox>
                </div>
            </div>
            <div v-if="data.required" class="col-12">
                <div class="form-group mb-3">
                    <label for="required_text">
                        Required text
                        <span class="text-danger small">*</span>
                    </label>
                    <input type="text" v-model="data.required_text" v-validate="{ required: true }"
                     id="required_text" class="form-control" name="required_text" :class="{ 'is-invalid': errors.has('required_text')}" placeholder="required_text" />
                    <span class="text-danger small" v-show="errors.has('required_text')">{{ errors.first('required_text') }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-3">
                    <label class="form-label" for="description">Description</label>
                    <textarea id="description"
                        v-model="data.description" class="form-control" rows="3"
                        placeholder="Write description" name="description"></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        productId:{
            type: Number,
            default: null
        },
        taskId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            data: {
                product_id: this.productId,
                task_id: this.taskId,
                name: "",
                title: "",
                type: "",
                kind: "",
                required_text: "",
                required: false,
                multiple: false,
                is_active: true,
                sort_order: 0,
                description: "",
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result) { this.alertError('Form not valid'); return; }
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/requirements/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.$store.commit("requirement/ADD_REQUIREMENT", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (!result) { this.alertError('Form not valid'); return; }
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/requirements/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$store.commit("requirement/UPDATE_REQUIREMENT", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        },
        patchUpdatables(){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    },
    mounted(){
        if(this.editMode){
            this.patchUpdatables()
        }
    }
}
</script>