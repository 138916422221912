
<template>
  <div class="requirements">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-sm border">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="my-0">
                Requirement
              </h3>
              <b-dropdown variant="light" right size="sm">
                <template slot="button-content"> Action
                </template>
                <b-dropdown-item-button @click="popupModalShow = true"><i class="mdi mdi-checkbox-blank-circle-outline"></i> Add requirement </b-dropdown-item-button>
                <b-dropdown-item-button @click="setClone('PRODUCT')" ><i class="mdi mdi-checkbox-blank-circle-outline"></i> Clone from product</b-dropdown-item-button>
                <b-dropdown-item-button @click="setClone('TASK')"><i class="mdi mdi-checkbox-blank-circle-outline"></i> Clone from task</b-dropdown-item-button>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div v-if="!isLoading" class="row">
      <div class="col-md-12">
        <div role="tablist" class="">
          <b-card no-body class="mb-1 shadow-none" 
            v-for="(requirement, index) in requirements" :key="index">
            <b-card-header header-tag="header" class="bg-transparent" role="tab">
              <div class="d-sm-flex">
                <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                  <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                    <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{requirement.name}}
                  </a>
                </h5>
              </div>
            </b-card-header>
            <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
              <b-card-body>
                <requirement-card :item="requirement">
                  <template v-slot:actions>
                    <div>
                      <b-button variant="success" size="sm" class="mr-2" @click.prevent="initEdit(requirement)" type="button">
                        Edit </b-button>
                      <b-button variant="primary" v-if="requirement.multiple" size="sm" class="mr-2" @click.prevent="showValues(requirement)" type="button">
                         View Values </b-button>
                      <b-button variant="danger" size="sm" @click.prevent="deleteItem(requirement.id)" type="button">
                        Delete</b-button>
                    </div>
                  </template>
                </requirement-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <is-loading v-else />


    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update requirement':'Add requirement'"
      title-class="" @hide="initializeAll()">
      <requirement-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :productId="productId" :taskId="taskId" :editItem="showItem" />
    </b-modal>

    <b-modal v-model="popupModalValues" size="lg" hide-footer centered
      title="Requirement values"
      title-class="" @hide="initializeAll()">
      <requirement-values v-if="popupModalValues"
       :showItem="showItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      :title="`Select ${cloneFrom ? cloneFrom.model.toLowerCase() : ''} to copy from`"
      title-class="font-18" @hide="initializeAll()">
      <div v-if="popupClone">
        <select v-model="cloneFrom.product_id" :disabled="cloneFrom.model != 'PRODUCT'"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(pItem, iIndex) in products" :key="iIndex"
          :value="pItem.id">{{pItem.name}}</option>
        </select>
        <select v-model="cloneFrom.task_id" :disabled="cloneFrom.model != 'TASK'"
          class="form-control mb-3" placeholder="--select task-">
          <option selected :value="null" >--select task--</option>
          <option v-for="(tItem, iIndex) in tasks" :key="iIndex"
          :value="tItem.id">{{tItem.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RequirementForm from '@/components/forms/RequirementForm.vue';
import RequirementCard from '@/components/cards/RequirementCard.vue';
import RequirementValues from './RequirementValues.vue';
import IsLoading from '@/components/IsLoading.vue';

export default {
  components: {
    RequirementForm, RequirementCard, 
    RequirementValues,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      popupModalValues: false,
      editMode: false,
      showItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed:{
    productId(){
      return +this.$route.query.product_id || null
    },
    taskId(){
      return +this.$route.query.task_id || null
    },
    requirements(){
      return this.$store.state.requirement.data
    },
    products(){
      return this.$store.state.productList.products.map((i) => {return {id: i.id, name: i.name}})
    },
    tasks(){
      return this.$store.state.taskList.data.map((i) => {return {id: i.id, name: i.name}})
    },
  },
  methods: {
    setClone(val){
      this.cloneFrom = {
        model: val,
        product_id: null,
        task_id: null,
        to: this.taskId || this.productId,
        model_to: this.taskId ? "TASK" : "PRODUCT"
      }
      this.popupClone = true
    },
    cloneNow(){
      if(!this.cloneFrom.product_id && !this.cloneFrom.task_id){
      this.alertError("Select product or task"); return;}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/requirements/clone', this.cloneFrom)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.initializeAll()
          this.fetchItems()
        }
      })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this requirement!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/requirements/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("requirement/DELETE_REQUIREMENT", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      this.showItem = {...item}
      this.popupModalValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
      this.popupModalValues = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
    fetchItems(){
      let payload = {}
      if(this.productId){ payload.product_id = this.productId }
      if(this.taskId){ payload.task_id = this.taskId }
      this.$store.dispatch("requirement/fetchRequirements", payload)
      .then((_) => { this.isLoading = false })
    }
  },
  mounted(){
    this.$store.dispatch("productList/fetchProducts")
    this.$store.dispatch("taskList/fetchTasks")
    this.fetchItems()
  }

}
</script>
